<template>
  <AppHeader />
    <main class="main-container container-fluid d-flex pt-20 px-0 position-relative">
     <app-sidebar />
     <article class="main-content mt-10">
      <Suspense>
        <template #default>
          <app-sports />
        </template>
        <template #fallback>
          <app-loading />
        </template>
      </Suspense>
     <AppFooter/>
   </article>
 </main>
 </template>

<script>
import AppHeader from '@/components/layouts/AppHeader.vue'
import AppFooter from '@/components/layouts/AppFooter.vue'
import AppSidebar from '@/components/layouts/AppSidebar.vue'
import AppSports from '@/components/Pages/GamesCategorys/AppSports.vue'
import { Suspense } from "vue";
import AppLoading from '../../components/Spinners/AppLoading.vue'
export default {
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
    AppSports,
    Suspense,
    AppLoading
  },
   name: 'SportsView'
}
</script>

<style>

</style>